import React from 'react'

import './index.css';

import { useTranslation } from 'react-i18next';

const FooterSection = React.lazy(() => import('./FooterSection'));
const HabitSettings = React.lazy(() => import('./HabitSettings'));
const LanguageSettings = React.lazy(() => import('./LanguageSettings'));
const ReferencesSection = React.lazy(() => import('./ReferencesSection'));
const PrivacyPolicySection = React.lazy(() => import('./PrivacyPolicySection'));
const TermsOfUseSection = React.lazy(() => import('./TermsOfUseSection'));
const PregnancySettings = React.lazy(() => import('./PregnancySettings'));
const IdeologySettings = React.lazy(() => import('./IdeologySettings'));
const AccountSettings = React.lazy(() => import('./AccountSettings'));

const Main = React.lazy(() => import('./Main'));

const SettingsPage = () => {
  const { t } = useTranslation(['settingsPage']);

  const sections = {
    main: {
      component: Main, // TODO: Refactor to make more consistent with other components
      screenClass: 'homePage', // TODO: This naming sucks
      title: false,
    },
    habitSettings: {
      component: HabitSettings,
      screenClass: '',
      title: t('settingsPage:habitSettings.title.text', 'Settings')
    },
    languageSettings: {
      component: LanguageSettings,
      screenClass: 'privacyPage', // TODO: This naming sucks
      title: t('settingsPage:languageSettings.title.text', 'Language')
    },
    pregnancySettings: {
      component: PregnancySettings,
      screenClass: '',
      title: t('settingsPage:pregnancySettings.title.text', 'Pregnancy')
    },
    ideologySettings: {
      component: IdeologySettings,
      screenClass: '',
      title: t('settingsPage:ideologySettings.title.text', 'Ideology')
    },
    accountSettings: {
      component: AccountSettings,
      screenClass: '',
      title: t('settingsPage:accountSettings.title.text', 'Account')
    },
    referencesSection: {
      component: ReferencesSection,
      screenClass: 'privacyPage',
      title: t('settingsPage:referencesSection.title.text', 'References')
    },
    privacyPolicySection: {
      component: PrivacyPolicySection,
      screenClass: 'privacyPage',
      title: t('settingsPage:referencesSection.privacy_policy.text', 'Privacy Policy')
    },
    termsOfUseSection: {
      component: TermsOfUseSection,
      screenClass: 'privacyPage',
      title: t('settingsPage:referencesSection.terms_of_use.text', 'Terms Of Use')
    },
  }

  const [sectionView, setSectionView] = React.useState('main')
  const klassName = sections[sectionView].screenClass
  const TheComponent = sections[sectionView].component
  const sectionTitle = sections[sectionView].title

  const navigateTo = (section) => {
    console.log('Navigating to: ', section)
    setSectionView(section)
  }

  return (
    <section className={`SettingsPage screen ${klassName}`}>
      <TheComponent navigateTo={navigateTo} title={sectionTitle}/>
      <FooterSection />
    </section>
  )
}

export default SettingsPage;

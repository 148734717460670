import React from 'react'
import { defaultSettings } from '@contexts/SettingsContext';

const useProfileSettings = ({i18n}) => {
  const [userProfileSettings, setUserProfileSettings] = React.useState(defaultSettings);

  const updateUserProfileSetting = React.useCallback((key, value) => {
    if (key in defaultSettings) {
      setUserProfileSettings((e) => {
        return {...e, [key]: value}
      })
    } else {
      throw new Error(`Invalid key: ${key}`)
    }
  },
    []
  )

  const language = userProfileSettings.languagePreference;
  const updateLanguage = React.useCallback((langCode) => {
    updateUserProfileSetting('languagePreference', langCode)
    // i18n.changeLanguage(langCode)
  },
  [i18n, updateUserProfileSetting]
  );

  const menstrual = userProfileSettings.menstrual;
  const updateCycleDuration = (duration) => {
    console.log('updateCycleDuration', duration)
    updateUserProfileSetting('menstrual', {
      cycle: {isKnown: true, value: duration},
      bleeding: menstrual.bleeding
    })
  }

  const updateMenstruationDuration = (duration) => {
    console.log('updateMenstruationDuration', duration)
    updateUserProfileSetting('menstrual', {
      cycle: menstrual.cycle,
      bleeding: {isKnown: true, value: duration}
    })
  }

  const pregnancy = userProfileSettings.pregnancy;
  const updatePregnancy = (dueDate) => {
    console.log('updatePregnancy', dueDate)
    updateUserProfileSetting('pregnancy', dueDate);
  }

  // React.useEffect(() => {
  //   i18n.on('languageChanged', (lng) => {
  //     document.documentElement.setAttribute('lang', lng);
  //     document.documentElement.setAttribute('dir', i18n.dir());
  //   });

  //   updateLanguage(userProfileSettings.languagePreference);
  // }, [i18n, updateLanguage, userProfileSettings.languagePreference]);

  return {
    userProfileSettings, updateUserProfileSetting,
    language, updateLanguage,
    menstrual, updateCycleDuration, updateMenstruationDuration,
    pregnancy, updatePregnancy,

  };
}

export default useProfileSettings;

import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { Auth } from 'aws-amplify'

// import PayButton from '@components/PayButton';
import OnboardingPage from '@components/OnboardingPage';
import SettingsPage from '@components/SettingsPage';
import SettingsContext from '@contexts/SettingsContext';
import useProfileSettings from '@hooks/useProfileSettings';

import { useTranslation } from 'react-i18next';

const checkUser = () => {
  Auth.currentAuthenticatedUser()
    .then(user => console.log({ user }))
    .catch(err => console.log(err))
}

const signOut = () => {
  Auth.signOut()
    .then(data => console.log(data))
    .catch(err => console.log(err))
}

const App = () => {
  const { t, i18n } = useTranslation(['settings']);
  const profileSettings = useProfileSettings({i18n});

  return (
    <SettingsContext.Provider value={profileSettings}>
      <div className="App">
        <Router>
          <Route exact path='/'>
            <button type="button" onClick={() => profileSettings.updateLanguage('fr')}>
              {t('settings:fr', 'French')}
            </button>

            <button type="button" onClick={() => profileSettings.updateLanguage('en')}>
              {t('settings:en', 'English')}
            </button>

            <button onClick={() => Auth.federatedSignIn()}>Sign In</button>
            <button onClick={checkUser}>Check User</button>
            <button onClick={signOut}>Sign Out</button>
          </Route>

          <Route path='/onboarding'>
            <OnboardingPage />
          </Route>

          <Route path='/settings'>
            <SettingsPage />
          </Route>

        </Router>

        {/* <PayButton /> */}
      </div>
    </SettingsContext.Provider>
  )
}

export default App

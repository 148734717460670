/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ca-central-1",
    "aws_cognito_identity_pool_id": "ca-central-1:fb777e0e-6b52-480e-b994-68abcf1f8e67",
    "aws_cognito_region": "ca-central-1",
    "aws_user_pools_id": "ca-central-1_49wTV6dqI",
    "aws_user_pools_web_client_id": "3ks7knl8v278idh5iuhh56dpqq",
    "oauth": {
        "domain": "ht-prod.auth.ca-central-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/,https://haidh-tracker.com/",
        "redirectSignOut": "http://localhost:3000/,https://haidh-tracker.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://xnunyec6ef.execute-api.ca-central-1.amazonaws.com/prod",
            "region": "ca-central-1"
        }
    ]
};


export default awsmobile;

import React from 'react'
import './general.css';

import { useTranslation } from 'react-i18next';

import SettingsContext from '@contexts/SettingsContext';

const OnboardingTutorialStart = React.lazy(() => import('./OnboardingTutorialStart'));
const OnboardingTutorialAnswerComponent = React.lazy(() => import('@helper_components/OnboardingTutorialAnswerComponent'));

const OnboardingPage = () => {
  const { t } = useTranslation(['onboarding']);

  const steps = [
    [OnboardingTutorialStart, 'none', {}],
    // [OnboardingTutorialAnswerComponent, 'calendar', {
    //   type: 'pregnancy',
    //   contents: {
    //     title: t('onboarding:titledQuestion.pregnancyCalendar.content'),
    //     modalContent: t('onboarding:titledQuestion.pregnancyCalendar.clarification'),
    //     wizardNext: t('onboarding:wizard.pregnancyDueDate'),
    //     wizardForgot: t('onboarding:wizard.skip.heading'),
    //     wizardForgotDetails: t('onboarding:wizard.skip.details'),
    //   },
    //   calendarSettings: {
    //     before: 24,
    //     after: 12
    //   },
    //   isMergeMultiStepWizardContainer: true,
    //   initialValue: {}
    // }],
    [OnboardingTutorialAnswerComponent, 'yearOfBirth', {
      type: 'yob',
      contents: {
        title: t('onboarding:titledQuestion.yearOfBirth.content'),
        modalContent: t('onboarding:titledQuestion.yearOfBirth.clarification'),
        wizardNext: t('onboarding:wizard.next'),
        wizardForgot: t('onboarding:wizard.skip.heading'),
        wizardForgotDetails: t('onboarding:wizard.skip.details'),
      },
      initialValue: 0
    }],
    [OnboardingTutorialAnswerComponent, 'numberSlider', {
      type: 'cycle',
      contents: {
        title: t('onboarding:titledQuestion.cycleLength.content'),
        modalContent: t('onboarding:titledQuestion.cycleLength.clarification'),
        wizardNext: t('onboarding:wizard.next'),
        wizardForgot: t('onboarding:wizard.forgot.heading'),
        wizardForgotDetails: t('onboarding:wizard.forgot.details'),
      },
      initialValue: 0
    }],
    [OnboardingTutorialAnswerComponent, 'numberSlider', {
      type: 'bleeding',
      contents: {
        title: t('onboarding:titledQuestion.menstruationLength.content'),
        modalContent: t('onboarding:titledQuestion.menstruationLength.clarification'),
        wizardNext: t('onboarding:wizard.next'),
        wizardForgot: t('onboarding:wizard.forgot.heading'),
        wizardForgotDetails: t('onboarding:wizard.forgot.details'),
      },
      initialValue: 0
    }],
    [OnboardingTutorialAnswerComponent, 'calendar', {
      type: 'period',
      contents: {
        title: t('onboarding:titledQuestion.periodCalendar.content'),
        modalContent: t('onboarding:titledQuestion.periodCalendar.clarification'),
        wizardNext: t('onboarding:wizard.savePeriodDates'),
        wizardForgot: t('onboarding:wizard.skip.heading'),
        wizardForgotDetails: t('onboarding:wizard.skip.details'),
      },
      calendarSettings: {
        before: 24,
        after: 0
      },
      isMergeMultiStepWizardContainer: true,
      initialValue: {}
    }],
  ];

  const initialWizardState = {
    step0: {},
    step1: {},
    step2: {},
    step3: {},
    step4: {},
  }

  const totalAmountSteps = steps.length;

  const {language, updateLanguage} = React.useContext(SettingsContext);
  const toggleLanguage = () => {
    if (language === 'ar') {
      updateLanguage('en');
    } else {
      updateLanguage('ar');
    }
  };

  const [currentStageIndex, setCurrentStageIndex] = React.useState(0)
  const [wizardState, setWizardState] = React.useState(initialWizardState)

  const currentOnboardingStepTag = `step${currentStageIndex}`
  const currentOnboardingStepComponentAdditionalState = steps[currentStageIndex][2]
  const CurrentOnboardingStepComponent = steps[currentStageIndex][0]
  const userInputMediumType = steps[currentStageIndex][1]

  const wizardProgress = {
    current: currentStageIndex,
    total: totalAmountSteps,
  }

  const nextStep = (stepState) => {
    if (stepState) {
      console.warn("Changing wizard state");
      setWizardState({...wizardState, [currentOnboardingStepTag]: stepState})
    }

    if (currentStageIndex + 1 < totalAmountSteps) {
      setCurrentStageIndex((i) => i + 1)
    }
  }

  console.log('wizardState', wizardState)
  return (
    <CurrentOnboardingStepComponent
      userInputMediumType={userInputMediumType}
      wizardProgress={wizardProgress}
      nextStep={nextStep}
      toggleLanguage={toggleLanguage}
      additionalState={currentOnboardingStepComponentAdditionalState}
    />
  )
}

export default OnboardingPage;

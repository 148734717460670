import React from 'react';

const SettingsContext = React.createContext();

export const defaultSettings = {
  religion: 'islam',
  madhhab: 'hanafi',
  languagePreference: 'en',
  dob: {isKnown: false, value: 'unknown'},
  menstrual: {
    cycle: {isKnown: true, value: 28},
    bleeding: {isKnown: true, value: 5},
  },
  pregnancy: false,
  travel: false,
  animations: true
}

export default SettingsContext;

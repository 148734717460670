import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import reportWebVitals from './reportWebVitals';

import Amplify from '@aws-amplify/core'
import awsconfig from './aws-exports'

// import './i18n';

// This workaround required as aws amplify does not handle multiple URIs well.
if (process.env.NODE_ENV === 'production') {
  awsconfig.oauth.redirectSignIn = `https://haidh-tracker.com/`;
  awsconfig.oauth.redirectSignOut = `https://haidh-tracker.com/`;
} else {
  awsconfig.oauth.redirectSignIn = `http://localhost:3000/`;
  awsconfig.oauth.redirectSignOut = `http://localhost:3000/`;
}

Amplify.configure(awsconfig)

const Spinner = (props) => {
  const { delay = 0 } = props;
  const [ready, setReady] = React.useState(delay === 0);
  React.useEffect(() => {
    let timeout = null;
    if (!ready) {
      timeout = setTimeout(() => setReady(true), delay);
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, []);
  if (!ready) return null;
  return (
    <div>Loading</div>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<Spinner />}>
      <App />
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
